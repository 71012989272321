<template>
	<div>
		<div class="container" v-loading="loading" element-loading-text="加载中"
			element-loading-background="rgb(255, 255, 255, 0.5)">
			<el-card>
				<div class="form-box">
					<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
						status-icon>
						<!-- 流程名称 -->
						<el-form-item label="流程名称" prop="name">
							<el-input v-model="form.name" placeholder="请填写流程名称" @input="retrieval"></el-input>
						</el-form-item>
						<!-- 请选择工会 -->
						<el-form-item label="选择工会" prop="choose">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = true"></el-input>
						</el-form-item>
						<!-- 是否开启 -->
						<el-form-item label="是否启用" prop="genre">
							<el-select v-model="form.genre" placeholder="请选择是否启用">
								<el-option label="启用" value="1"
									style="width:100%; text-align-last: center;"></el-option>
								<el-option label="禁用" value="2"
									style="width:100%; text-align-last: center;"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否要求签名" prop="signature">
							<el-select v-model="form.signature" placeholder="请选择是否要求签名">
								<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
								<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="流程类型" prop="type">
							<el-select v-model="form.type" placeholder="请选择流程类型">
								<el-option label="建言献策" value="1"
									style="width:100%; text-align-last: center;"></el-option>
								<el-option label="会员关爱" value="2"
									style="width:100%; text-align-last: center;"></el-option>
								<el-option label="主席信箱" value="3"
									style="width:100%; text-align-last: center;"></el-option>
								<el-option label="健康问诊" value="4"
									style="width:100%; text-align-last: center;"></el-option>
							</el-select>
						</el-form-item>
						<!-- 缴费类型 -->
						<!-- <el-form-item label="缴费类型" prop="name">
								<el-select v-model="form.name" placeholder="请选择缴费类型">
								  <el-option label="按月" value="1" style="width:100%; text-align-last: center;"></el-option>
								  <el-option label="按年" value="2" style="width:100%; text-align-last: center;"></el-option>
								</el-select>
							  </el-form-item> -->
						<!-- 微信商户号 -->
						<!-- <el-form-item label="微信商户号" prop="name">
								<el-input v-model="form.name"  placeholder="请填写微信商户号"></el-input>
							  </el-form-item> -->
						<!-- 商户支付KEY -->
						<!-- <el-form-item label="商户支付KEY" prop="payment">
							<el-input v-model="form.payment"  placeholder="请填写商户支付KEY"></el-input>
						  </el-form-item> -->
						<!-- 缴费说明 -->
						<el-form-item label="备注" prop="remark">
							<el-input type="textarea" v-model="form.remark" placeholder="请填写备注"></el-input>
						</el-form-item>

						<!-- 审批流程 -->
						<div class="sheet">
							<div class="dati"><span>*</span>审批流程</div>
							<div class="lation">
								<div class="twist"><el-button type="primary"
										style="width: 90px;height: 30px;font-size: 16px;" size="small"
										@click="copyreader">新增</el-button></div>
								<!-- 等级审批 -->
								<div class="shuomings" v-for="(item,index) in topic" :key="index">
									<div class="removing">
										<div class="hierarchy">
											第{{index+1}}级审批：
										</div>
										<!-- 删除 -->
										<el-button size="default" @click="expurgate(index)"
											v-show="index!=topic.length-1||index==0?false:true" class="red"
											:disabled="forbidden"><el-icon class="cancel">
												<Delete />
											</el-icon>删除</el-button>
									</div>
									<div>
										<!-- style="margin-bottom: 20px !important;" -->
										<div class="really" v-for="(it,indx) in item.userName" :key="indx">
											<div class="datis"><span>*</span>审批人</div>
											<div class="circu">
												<div>
													<!-- <el-input v-model="it.nameAndUserName"
														:class="it.affirm==1?'owner':'necessary'" style="width:210px;"
														readonly @click="candidate(index,indx)" placeholder="请选择审批人"
														size="large"> -->
													<el-input v-model="it.nameAndUserName" style="width:210px;" readonly
														@click="candidate(index,indx)" placeholder="请选择审批人"
														size="large">
														<template #suffix>
															<el-icon v-show="it.affirm==1?false:true"
																class="difference">
																<CircleClose />
															</el-icon>
														</template>
													</el-input>
													<!-- <div class="integrant" v-show="it.affirm==1?false:true">请选择审批人</div> -->
												</div>
												<!-- v-show="index=0?true:false" -->
												<el-button size="default" @click="minitype(index,indx)"
													v-show="indx==0?false:true" class="red"
													:disabled="forbidden"><el-icon class="cancel">
														<Delete />
													</el-icon>删除</el-button>
											</div>
										</div>
										<!-- 增加审批人 -->
										<div class="fortify">
											<div class="generate">
												增加审批人
											</div>
											<div class=""><el-button type="primary" class="shallow"
													style="width: 90px;height: 30px;font-size: 16px;" size="small"
													@click="newly(index)">新增</el-button></div>
										</div>
									</div>
								</div>

							</div>
						</div>

						<!-- 提交按钮 -->
						<div class="submit">
							<el-form-item>
								<el-button type="primary" style="margin-top: 30px;" :loading="loa"
									@click="onSubmit">提交</el-button>
							</el-form-item>
						</div>
						<!-- 所属工会选择 -->
						<div>
							<s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']"
								@yes="yes">
								<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
							</s3-layer>
						</div>




						<!-- 选择审批人 -->
						<el-dialog v-model="carry" width="50%">
							<template #header>
								<div class="minitype">审批人</div>
								<!-- <div class="trousers">
                <img class="tishi" src="" alt="">
                <div>系统任务日志仅当天有效，过期自动删除</div>
              </div> -->
							</template>
							<!-- 搜索  -->
							<div class="search">
								<el-input class="hunt" v-model="searchEncoding" placeholder="请输入会员编码" size="large"
									@input="orangutan" />
								<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
									:loading='formative'><el-icon class="cancelgu">
										<Search />
									</el-icon>搜索</el-button>
							</div>
							<div class="situation" v-if="!assignment.length">
								<h3 style="display: flex;justify-content: center;align-items: center;height: inherit;"
									v-if="!assignment.length">请检索审批人</h3>
							</div>
							<div class="situation" v-if="assignment.length">
								<!-- 审批人弹窗打开默认字样 -->

								<!-- 审批人弹窗搜索后出现表单样式 -->
								<el-table :data="assignment" style="width: 100%" row-key="unionId"
									@cell-mouse-enter="obtain" border :header-cell-style="{ background: '#F5F7FA' }"
									:empty-text="texts">
									<el-table-column prop="username" label="会员编码" align="center"
										:show-overflow-tooltip='true' />
									<el-table-column prop="name" label="会员姓名" align="center"
										:show-overflow-tooltip='true' />
									<!-- <el-table-column prop="state" label="状态" :formatter="condition1"  align="center" :show-overflow-tooltip='true'/> -->
									<el-table-column label="操作" prop="state" align="center">
										<template #default="scope">
											<el-button size="default" @click="affirm(scope.row.username,scope.row.name)"
												class="bulur"><el-icon class="cancel">
													<Check />
												</el-icon>确认</el-button>
											<div v-show="scope.row.state==2">-</div>
										</template>
									</el-table-column>
								</el-table>
								<!-- 一页时候隐藏 hide-on-single-page="true" -->
								<!-- <el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes"
									:page-sizes="[6, 12, 18, 24]" v-model:currentPage="currentPage"
									:page-size="pagesize" :total="altogether" @size-change="handleSizeChange"
									@current-change="handleCurrentChange" /> -->
							</div>
							<template #footer>
								<span class="dialog-footer">
									<el-button type="primary" @click="carry = false">
										关闭
									</el-button>
								</span>
							</template>
						</el-dialog>
					</el-form>
				</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted,
		nextTick,
		onActivated
	} from "vue";
	import {
		ZClist,
		HFruleadd,
		AddProcess,
		Hylist,
		ObtainApprover,
		ProcessInfo,
		EditProcess,
	} from '../../utils/api'
	import {
		ElMessage,

	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		Plus,
		CirclePlus,
		Delete,
		Search,
		Check,
		CircleClose,
		search
	} from '@element-plus/icons-vue'
	import moments from 'moment'
	export default {
		name: "processedit",
		components: {
			Plus,
			CirclePlus,
			Delete,
			Search,
			Check,
			CircleClose
		},
		setup() {
			//获取列表页传来的code
			const route = useRoute()
			let loading = ref(true)
			let spChildIndex = ref(0)
			//流程详情获取
			let ProcessInfos = () => {
				let data = {
					code: sessionStorage.getItem('processIds')
				}
				var userNames = ref([])
				const code = ref([])
				ProcessInfo(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// 流量名称
						form.name = res.data.data.flowInfo.title
						// 选择工会
						gaincode.value = res.data.data.flowInfo.unionCode
						messge()
						//是否启用
						// form.genre= res.data.data.flowInfo.isEnabled
						if (res.data.data.flowInfo.isEnabled == 1) {
							form.genre = '启用'
						} else if (res.data.data.flowInfo.isEnabled == 2) {
							form.genre = '禁用'
						}
						//是否签名
						if (res.data.data.flowInfo.isSignature == 1) {
							form.signature = '是'
						} else if (res.data.data.flowInfo.isSignature == 2) {
							form.signature = '否'
						}
						//流程类型
						if (res.data.data.flowInfo.type == 1) {
							form.type = '建言献策'
						} else if (res.data.data.flowInfo.type == 2) {
							form.type = '会员关爱'
						} else if (res.data.data.flowInfo.type == 3) {
							form.type = '主席信箱'
						} else if(res.data.data.flowInfo.type == 4){
							form.type = '健康问诊 '
						}
						//备注
						form.remark = res.data.data.flowInfo.remarks
						//审批人
						topic.value = res.data.data.flowInfo.flowUserNames
						topic.value.forEach((item, index) => {
							item.userName.forEach((it, index) => {
								userNames.value.push(it.userName)
							})
							//获取code
							code.value = res.data.data.flowInfo.code
						})
						// [divide.value].constitute = res.data.data.flowInfo.flowUserNames.userName
						// console.log(entire.value, divide.value)
					}
				})
			}

			// 选择组织
			// let ele = ref('')
			// ele.value=gaincode.value
			// if(ele.value==''){
			//   ele.value=.value
			// }
			// 整个数组index 
			// 整个数组index
			const entire = ref()
			// 分数组index
			let divide = ref(0)
			const affirm = (username, name) => {
				topic.value[entire.value].userName[spChildIndex.value].nameAndUserName = name + '(' + username + ')'
				topic.value[entire.value].userName[spChildIndex.value].userName = username
				// topic.value[entire.value].option[divide.value].constitute = name + '(' + username + ')'
				// topic.value[entire.value].option[divide.value].username = username
				// topic.value[entire.value].option[divide.value].name = name
				// repetition.value[entire.value].option[divide.value].username = username
				// repetition.value[entire.value].option[divide.value].name = name
				carry.value = false
				searchEncoding.value = ''
				assignment.value = []
			}


			// 点击选择审批人弹框
			let assignment = ref([])
			const candidate = (index, inde) => {
				spChildIndex.value = inde
				carry.value = true
				searchEncoding.value = ''
				assignment.value = []
				entire.value = index
				divide.value = inde

				carry.value = true
				// // console.log(index,inde)
			}
			let search1 = () => {
				formative.value = true
				search()
			}
			let search = () => {
				let texts = ref('加载中')
				let option = ref('')
				let data = {
					username: searchEncoding.value
				}
				assignment.value = []
				//弹窗搜索的数据
				ObtainApprover(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						formative.value = false
						// 审批人列表数据
						assignment.value.push(res.data.data.userInfo)
					} else {
						ElMessage({
							message: res.data.msg,
							type: "warning",
							center: true,
						});
						searchEncoding.value = ''
						assignment.value = []
						formative.value = false
					}
				})
			}
			// const list = () => {
			// 	let data = {
			// 		limit: branches.value,
			// 		page: currentPage.value,
			// 		username:input.value
			// 	}
			// 	ObtainApprover(qs.stringify(data)).then((res) => {
			// 		console.log(res)
			// 		// altogether.value = res.data.data.page.totalCount
			// 		// filterTableData.value = res.data.data.page.list
			// 		// if (filterTableData.value.length == 0) {
			// 		// 	texts.value = "暂无数据"
			// 		// }
			// 	})
			// }

			// // 搜索审批人数组
			// let input= ref('')
			// let search1 = () => {
			// 	formative.value = true
			// 	search()
			// }
			// const search = () => {
			// 	texts.value = "加载中"
			// 	console.log(monicker.value)
			// 	console.log(encipher.value)
			// 	console.log(ability.value)
			// 	let data = {
			// 		limit: branches.value,
			// 		page: currentPage.value,
			// 		name: monicker.value,
			// 		username: encipher.value,
			// 		unionCode: Hcode.value
			// 	}
			// 	Hylist(qs.stringify(data)).then((res) => {
			// 		console.log(res)
			// 		formative.value = false
			// 		altogether.value = res.data.data.page.totalCount
			// 		filterTableData.value = res.data.data.page.list
			// 		if (filterTableData.value.length == 0) {
			// 			texts.value = "暂无数据"
			// 		}
			// 	})
			// }
			// const assignment = () => {
			// 	Zzlist({limit:10,page:1}).then((res) => {
			// 		// console.log(res)
			// 		// texture.value = res.data.data.wholeUnionList
			// 	})
			// }

			// const assignment = ref([{
			// 	id: 'JTBL1503',
			// 	name: '张三'
			// }, {
			// 	id: 'JTB1503',
			// 	name: '李四'
			// }])
			// 审批人弹窗
			const carry = ref(false)
			// 删除审批人
			const minitype = (index, indx) => {
				// console.log(topic.value)
				topic.value[index].userName.splice(indx, 1)
			}
			// 删除等级审批
			const expurgate = (index) => {
				topic.value.splice(index, 1)
			}
			const newly = (index) => {
				// console.log(form.pattern)
				if (!topic.value[index].userName) {
					topic.value[index].userName = []
				}
				topic.value[index].userName.push({
					nameAndUserName: '',
					id: '',
					constitute: '',
					affirm: 1
				})
				repetition.value[index].option.push({
					name: '',
					id: ''
				})
				// if(topic.value[index].option.length>1){
				//   Choice.value=false
				// }
				// console.log(topic.value[index].option)
			}


			const regime = ref('')
			// 等级审批添加
			const copyreader = () => {
				topic.value.push({
					userName: [{
						nameAndUserName: ''
					}]
				})
				repetition.value.push({
					option: [{
						name: '',
						id: ''
					}]
				})
			}
			// 审批流程
			const topic = ref([{
				userName: [{
					nameAndUserName: ''
				}]
			}])

			const repetition = ref([{
				option: [{
					name: '',
					id: '',
				}],
			}])

			//会员姓名输入框改变
			const orangutan = () => {
				// if(input.value==''&&ability.value==''&&values.value==''){
				//   list()
				// }
				this.$forceUpdate()
			}
			// 弹窗搜索输入框值
			let searchEncoding = ref('')
			// 搜索加载
			const formative = ref(false)
			//页面进入加载
			onMounted(() => {
				ProcessInfos()
			})
			// 获取工会列表信息
			const messge = () => {
				ZClist().then((res) => {
					texture.value = res.data.data.manageUnionList
					const arr3 = texture.value.filter(function(num) {
						return num.unionCode == gaincode.value
					})
					if (arr3.length == 0) {
						texture.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == gaincode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == gaincode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((ite) => {
											const arr6 = ite.unionDtoList.filter(
												function(numss) {
													return numss.unionCode ==
														gaincode.value
												})
											if (arr6.length == 0) {
												ite.unionDtoList.forEach((it) => {
													const arr7 = it
														.unionDtoList.filter(
															function(numsss) {
																return numsss
																	.unionCode ==
																	gaincode
																	.value
															})
													if (arr7.length == 0) {
														it.unionDtoList
															.forEach((i) => {
																const
																	arr8 =
																	i
																	.unionDtoList
																	.filter(
																		function(
																			numssss
																		) {
																			return numssss
																				.unionCode ==
																				gaincode
																				.value
																		})
																if (arr8
																	.length ==
																	0) {
																	i.unionDtoList
																		.forEach(
																			(
																				s
																			) => {
																				const
																					arr9 =
																					s
																					.unionDtoList
																					.filter(
																						function(
																							nu
																						) {
																							return nu
																								.unionCode ==
																								gaincode
																								.value
																						}
																					)
																				if (arr9
																					.length ==
																					0
																				) {
																					s.unionDtoList
																						.forEach(
																							(
																								a
																							) => {
																								const
																									arr10 =
																									a
																									.unionDtoList
																									.filter(
																										function(
																											cat
																										) {
																											return cat
																												.unionCode ==
																												gaincode
																												.value
																										}
																									)
																								if (arr10
																									.length ==
																									0
																								) {
																									a.unionDtoList
																										.forEach(
																											(
																												d
																											) => {
																												const
																													arr11 =
																													d
																													.unionDtoList
																													.filter(
																														function(
																															dog
																														) {
																															return dog
																																.unionCode ==
																																gaincode
																																.value
																														}
																													)
																												if (arr11
																													.length ==
																													0
																												) {
																													d.unionDtoList
																														.forEach(
																															(
																																f
																															) => {
																																const
																																	arr12 =
																																	f
																																	.unionDtoList
																																	.filter(
																																		function(
																																			long
																																		) {
																																			return long
																																				.unionCode ==
																																				gaincode
																																				.value
																																		}
																																	)
																																arr12
																																	.forEach(
																																		(
																																			item
																																		) => {
																																			form.choose =
																																				arr3
																																				.unionName =
																																				item
																																				.unionName
																																			gaincode
																																				.value =
																																				arr3
																																				.unionCode =
																																				item
																																				.unionCode
																																		}
																																	)
																															}
																														)
																												}
																												arr11
																													.forEach(
																														(
																															item
																														) => {
																															form.choose =
																																arr3
																																.unionName =
																																item
																																.unionName
																															gaincode
																																.value =
																																arr3
																																.unionCode =
																																item
																																.unionCode
																														}
																													)
																											}
																										)
																								}
																								arr10
																									.forEach(
																										(
																											item
																										) => {
																											form.choose =
																												arr3
																												.unionName =
																												item
																												.unionName
																											gaincode
																												.value =
																												arr3
																												.unionCode =
																												item
																												.unionCode
																										}
																									)
																							}
																						)
																				}
																				arr9.forEach(
																					(
																						item
																					) => {
																						form.choose =
																							arr3
																							.unionName =
																							item
																							.unionName
																						gaincode
																							.value =
																							arr3
																							.unionCode =
																							item
																							.unionCode
																					}
																				)
																			}
																		)
																}
																arr8.forEach(
																	(
																		item
																	) => {
																		form.choose =
																			arr3
																			.unionName =
																			item
																			.unionName
																		gaincode
																			.value =
																			arr3
																			.unionCode =
																			item
																			.unionCode
																	})
															})
													}
													arr7.forEach((item) => {
														form.choose =
															arr3
															.unionName =
															item
															.unionName
														gaincode
															.value =
															arr3
															.unionCode =
															item
															.unionCode
													})
												})
											}
											arr6.forEach((item) => {
												form.choose = arr3.unionName =
													item.unionName
												gaincode.value = arr3
													.unionCode = item.unionCode
											})
										})
									}
									arr5.forEach((item) => {
										form.choose = arr3.unionName = item.unionName
										gaincode.value = arr3.unionCode = item
											.unionCode
									})
								})
							} else {
								arr4.forEach((item) => {
									form.choose = arr3.unionName = item.unionName
									gaincode.value = arr3.unionCode = item.unionCode
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.choose = arr3.unionName = item.unionName
							gaincode.value = arr3.unionCode = item.unionCode
						})
					}
					// loading.value = false
					// cat.value='auto'
					// console.log(res)

				})
			}
			// 所属工会选择
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			let texture = ref([])
			const visible = ref(false);
			//按code查询更新
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				if (translate.value == 1) {
					loading.value = true
					ProcessInfos()
				}
			})
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				name: "",
				genre: "",
				choose: "",
				signature: "",
				remark: "",
				type: "",
				nameAndUserName: "",
				constitute: "",
				affirm: 1
			})
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			// 表单提交
			// 审批人是否选择
			const sphere = ref(2)
			const router = useRouter();
			const loa = ref(false)
			const onSubmit = () => {
				ruleFormRef.value.validate((valid) => {
					if(valid==false){
					  ElMessage({
					    message: "提交失败",
					    type: "error",
					    center: true,
					  });
					}else if(valid==true){
						let userNames = ref([])
						let code = ref([])
						let flowUserNames = []
						topic.value.forEach((item, index) => {
							// console.log(item.userName)
							// item.userName.forEach((it, index) => {
							// 	console.log(it.userName)
						
							// 	userNames.value.push(it.userName)
							// 	console.log(userNames.value)
							// })
							let userName = item.userName.map(i => i.userName)
							flowUserNames.push({
								level: index + 1,
								userName
							})
						})
						let genres = ref(null)
						if (form.genre == '启用') {
							genres.value  = 1
						} else if (form.genre == '禁用') {
							genres.value  = 2
						}else if(form.genre == 1){
							genres.value  = 1
						}else if(form.genre == 2){
							genres.value  = 2
						}
						let signatures = ref(null)
						if (form.signature == '是') {
							signatures.value = 1
						} else if (form.signature == '否') {
							signatures.value = 2
						}else if(form.signature == 1){
							signatures.value  = 1
						}else if(form.signature == 2){
							signatures.value  = 2
						}
						let types = ref(null)
						if (form.type == '建言献策') {
							types.value = 1
						} else if (form.type == '会员关爱') {
							types.value = 2
						} else if (form.type == '主席信箱') {
							types.value = 3
						} else if(form.type == '健康问诊') {
							types.value = 4
						} else if (form.type == 1) {
							types.value = 1
						} else if (form.type == 2) {
							types.value = 2
						} else if (form.type == 3) {
							types.value = 3
						} else if(form.type == 4) {
							types.value = 4
						}
						let data = {
							code: sessionStorage.getItem('processIds'),
							title: form.name,
							unionCode: gaincode.value,
							isEnabled: genres.value,
							isSignature: signatures.value ,
							type: types.value,
							remarks: form.remark,
							flowUserNames
						}
						EditProcess(qs.parse(data)).then((res) => {
							if (res.data.code == 0) {
								if (res.data.code == 0) {
									ElMessage({
										message: "提交成功",
										type: "success",
										center: true,
									});
									setTimeout(() => {
										current.value = route.path
										expression.value = store.state.tagsList
						
										expression.value.forEach((item, index) => {
											if (current.value == item.path) {
												Indexs.value = index
											}
										})
										store.state.tagsList.splice(Indexs.value, 1)
										router.push({
											name: 'process',
											params: {
												userId: 1
											}
										});
										loa.value = false
									}, 500)
								}
							} else {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								loa.value = false
							}
						})
					}
				})

				// topic.value.forEach((item) => {
				// 	item.option.forEach((it) => {
				// 		if (it.constitute == '') {
				// 			it.affirm = 2
				// 			sphere.value = 2
				// 		} else if (it.constitute !== '') {
				// 			it.affirm = 1
				// 			sphere.value = 1
				// 		}
				// 	})
				// })

				// ruleFormRef.value.validate((valid) => {
				// 	console.log(valid)
				// 	if (valid == false) {
				// 		ElMessage({
				// 			message: "提交失败",
				// 			type: "error",
				// 			center: true,
				// 		});
				// 	} else if (valid == true) {
				// 		loa.value = true
				// 		let data = {
				// 			title: form.name,
				// 			unionCode: zzcode.value,
				// 			isEnabled: form.genre,
				// 			isSignature: form.signature,
				// 			type: form.choose,
				// 			remarks: form.remark,
				// 			flowUserNames: [{
				// 				level: 1,
				// 				userName: [
				// 					"JTB0200001",
				// 					"JTB0200003"
				// 				]
				// 			}]
				// 		}
				// 	Hyadd(qs.parse(data)).then((res) => {
				// 		console.log(res)
				// 		if (res.data.code == 0) {
				// 			if (res.data.code == 0) {
				// 				ElMessage({
				// 					message: "提交成功",
				// 					type: "success",
				// 					center: true,
				// 				});
				// 				setTimeout(() => {
				// 					router.push({
				// 						name: 'member',
				// 						params: {
				// 							userId: 1
				// 						}
				// 					});
				// 					form.choice = ''
				// 					form.name = ''
				// 					form.genre = ''
				// 					zzcode.value = ''
				// 					form.password = ''
				// 					form.sex = ''
				// 					form.date = ''
				// 					form.folk = ''
				// 					form.visage = ''
				// 					form.education = ''
				// 					form.role = ''
				// 					form.soldier = ''
				// 					form.cellphone = ''
				// 					form.marriage = ''
				// 					form.mailbox = ''
				// 					form.remark = ''
				// 					loa.value = false
				// 				}, 500)
				// 			}
				// 		} else if (res.data.code == 2008) {
				// 			ElMessage({
				// 				message: "会员编码重复",
				// 				type: "warning",
				// 				center: true,
				// 			});
				// 			loa.value = false
				// 		}
				// })
				// }

				// // console.log(topic.value)
				// console.log(repetition.value)
				// // console.log('工会',gaincode.value)
				// // console.log('是否开启缴费',form.genre)
				// // console.log('缴费类型',form.name)
				// // console.log('缴费说明',form.remark)
				// // router.push({ name: 'HFregulation', params: { userId: 1 }});
				// loa.value = true
				// // 表单校验
				// ruleFormRef.value.validate((valid) => {
				// 	loa.value = false
				// 	if (valid == true && sphere.value == 1) {

				// 		console.log('成功')
				// 	// 	}
				// })
			}

			// 验证表单提示
			const rules = {
				name: [{
					required: true,
					message: "请填写流程名称",
					trigger: "blur"
				}, ],
				genre: [{
					required: true,
					message: "请选择是否启用",
					trigger: "blur"
				}, ],
				signature: [{
					required: true,
					message: "请选择是否要求签名",
					trigger: "blur"
				}, ],
				type: [{
					required: true,
					message: "请选择流程类型",
					trigger: "change"
				}, ],
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				remark: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ],
			}
			return {
				spChildIndex,
				// 弹出窗确认按钮
				affirm,
				// 点击选择审批人框
				candidate,
				// 搜索审批人数组
				assignment,
				// 审批人弹窗
				carry,
				minitype,
				expurgate,
				newly,
				// 审批流程
				regime,
				copyreader,
				topic,
				// 提交按钮
				loa,
				// 树形选择内容
				yes,
				visible,
				dendrogram,
				defaultProps,
				texture,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules,
				formative,
				search1,
				status: 1, //审批人的弹窗默认提示
				searchEncoding,
			}
		},
		components: {
			Delete,
			Search,
			Plus
		},
	}
</script>>

<style lang='scss' scoped>
	.difference {
		color: var(--el-color-danger);
		pointer-events: all;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	/* .integrant {
		color: var(--el-color-danger);
		font-size: 12px;
	} */

	.owner ::v-deep .el-input__wrapper {
		// box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) !important;
	}

	::v-deep .owner {}

	.necessary ::v-deep .el-input__wrapper {
		box-shadow: none !important;
	}

	::v-deep .necessary {
		border: 1px solid var(--el-color-danger);
		border-radius: var(--el-input-border-radius, var(--el-border-radius-base));

		// border-radius: var(--el-input-border-radius,var(--el-border-radius-base));
	}

	.really {
		display: flex;
		// margin-bottom: 25px;
		height: 70px;
	}

	.shallow {
		color: #ffffff;
		border: none;
		--el-button-bg-color: #00BFFF;
		--el-button-hover-bg-color: #87CEFA;
		--el-button-hover-text-color: #ffffff;
	}

	.shallow:hover {
		color: #ffffff;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	.hunt {
		width: 30%;
		margin-right: 10px;
		margin-bottom: 20px;
	}

	::v-deep .el-dialog__body {
		padding: 5px 20px 20px 20px;
	}

	.situation {
		padding-bottom: -50px !important;
		height: 215px !important;
		overflow: auto !important;
	}

	.situation::-webkit-scrollbar {
		display: none !important;
	}

	.removing {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 380px;
		height: 40px;
	}

	.shuomings {
		margin-bottom: 30px;
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	.generate {
		font-size: 14px;
		color: var(--el-text-color-regular);
		margin-left: 14px;
		margin-right: 10px;
	}

	::v-deep .fortify {
		display: flex;
		align-items: center;
		// margin-top: 20px;
	}

	.hierarchy {
		padding-left: 12px;
		// margin-bottom: 10px;
		font-size: 14px;
		font-weight: bold;
		color: var(--el-text-color-regular);
	}

	// .approver ::v-deep .el-form-item{
	//     margin-bottom: 20px !important;
	//     align-items: center;
	// }
	.approver ::v-deep .el-form-item__label {
		width: 70px;
	}

	::v-deep .red,
	.red:focus:not(.red:hover) {
		color: red;
	}

	::v-deep .red {
		font-size: 20px;
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	::v-deep .red:hover {
		color: #fab6b6;
	}

	::v-deep .circu {
		display: flex;
		// align-items: center;
		width: 100%;
		// margin-bottom: 20px;
	}

	::v-deep .cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	::v-deep .twist {
		margin-left: 13px;
		margin-top: 8px;
		margin-bottom: 20px;
	}

	::v-deep .lation {
		width: 100%;
	}

	::v-deep .datis {
		font-weight: bold;
		width: 75px;
		/* float: right; */
		display: flex;
		font-size: 14px;
		justify-content: flex-end;
		margin-top: 13px;
		margin-right: 10px;
		color: var(--el-text-color-regular);

		span {
			color: var(--el-color-danger);
			margin-right: 3px;
		}
	}

	::v-deep .dati {
		font-weight: bold;
		width: 120px;
		/* float: right; */
		display: flex;
		font-size: 14px;
		justify-content: flex-end;
		margin-top: 13px;
		color: var(--el-text-color-regular);

		span {
			color: var(--el-color-danger);
			margin-right: 3px;
		}
	}

	::v-deep .sheet {
		display: flex;
	}


	::v-deep .el-form-item__label {
		width: 108px;
	}

	::v-deep .el-form-item__label {
		font-weight: bold;
	}

	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .submit {
		margin-left: 105px;
	}

	::v-deep .el-input {
		height: 44px;
	}

	::v-deep .el-form-item {
		margin-bottom: 30px;
		align-items: center;
	}

	::v-deep .el-tree-node__content {
		font-weight: 500;
	}

	::v-deep .el-select-dropdown__item.hover {
		background-color: #ffffff;
	}

	::v-deep .el-select-dropdown__wrap {
		background-color: #ffffff;
	}

	::v-deep .form-box {
		width: 500px;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .container {
		max-width: 98%;
		margin: auto;
		margin-top: 10px;
		// padding: 30px;
		background: #fff;
		// border: 1px solid #ddd;
		border-radius: 5px;
		height: calc(100vh - 130px);
		overflow: auto;
	}

	.container::-webkit-scrollbar {
		display: none;
	}
</style>